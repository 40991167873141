import React, { useState } from 'react';
import { UilShareAlt } from '@iconscout/react-unicons';
import css from './ShareButton.module.css';
import { FacebookIcon, InstapaperIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import {
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import QRCode from 'react-qr-code';
import qrIcon from './icons/qrIcon.png';
import { downloadDivAsImage } from './utils';
import Modal from '../Modal/Modal';

const ShareButton = ({ setOpen }) => {
  return (
    <div
      className={css.share}
      // onClick={e => {
      //   e.stopPropagation();
      //   e.preventDefault();
      //   setShowShare(true);
      // }}
    >
      <UilShareAlt className={css.icon} />{' '}
      <div className={css.shareButtonsWrapper}>
        <FacebookShareButton url={typeof window !== 'undefined' && window.location.href}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TelegramShareButton url={typeof window !== 'undefined' && window.location.href}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <TwitterShareButton url={typeof window !== 'undefined' && window.location.href}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={typeof window !== 'undefined' && window.location.href}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <div className={css.customIconWrapper} onClick={() => setOpen(true)}>
          <img src={qrIcon} className={css.customIcon} />
        </div>
      </div>
    </div>
  );
};

export default ShareButton;
