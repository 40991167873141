import React from 'react';
import css from './TipModal.module.css';
import { Button, Modal } from '../../../components';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { useConfiguration } from '../../../context/configurationContext';

const { UUID, Money } = sdkTypes;

const TipModal = props => {
  const { formApi, lineItems, intl, isOpenChange, isOpenWhy, onCloseChange, onCloseWhy } = props;

  const tipLineItem = lineItems.find(i => i?.code === 'line-item/tip');
  let donationLineItem = lineItems.find(i => i?.code === 'line-item/item');

  if (donationLineItem === undefined) {
    donationLineItem = lineItems.find(i => i?.code === 'line-item/item-for-sale');
  }

  const tipUnitPrice = tipLineItem && new Money(tipLineItem?.lineTotal?.amount, tipLineItem?.unitPrice?.currency);
  const donationUnitPrice = donationLineItem && donationLineItem?.lineTotal;
  const formattedTipLabel = formatMoney(intl, tipUnitPrice);
  const [tipLabel, setTipLabel] = useState(formattedTipLabel);
  //const [changeModalOpen, setChangeModalOpen] = useState(false);
  //const [whyDonateModalOpen, setWhyDonateModalOpen] = useState(false);

  const [supportCheckboxValue, setSupportCheckboxValue] = useState(false);
  const [tipAmount, setTipAmount] = useState(tipUnitPrice?.amount || 0);
  const config = useConfiguration();
  const sliderMax = donationUnitPrice?.amount && donationUnitPrice?.amount * 0.4;
  const sliderMin = 50;

  const handleSave = () => {
    if (!supportCheckboxValue) {
      formApi.change('tip', new Money(tipAmount, config.currency));
    } else {
      formApi.change('tip', new Money(0, config.currency));
    }
    //setChangeModalOpen(false);
    onCloseChange();
  };
  return (
    <div className={css.wrapper}>
      {/* <div className={css.actionLinks}>
        <p className={css.link} onClick={() => setWhyDonateModalOpen(true)}>
          <FormattedMessage id="TipModal.whyDonate" />
        </p>
        <p className={css.separator}>•</p>
        <p className={css.link} onClick={() => setChangeModalOpen(true)}>
          <FormattedMessage id="TipModal.change" />
        </p>
      </div> */}

      <Modal
        isOpen={isOpenChange}
        onClose={onCloseChange}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.modalContent}>
          <p className={css.modalTitle}>
            <FormattedMessage id="TipModal.changeModalTitle" />
          </p>

          {!supportCheckboxValue && (
            <div className={css.sliderWrapper}>
              <p className={css.currentTipValue}>
                <FormattedMessage
                  id={'TipModal.currentTipValue'}
                  values={{ currentTipValue: tipLabel }}
                />
              </p>
              <div className={css.sliderBarWrapper}>
                <Slider
                  step={50}
                  size="medium"
                  min={sliderMin}
                  max={sliderMax}
                  value={tipAmount}
                  onChange={(event, newValue) => {
                    const newTipUnitPrice = new Money(newValue, config.currency);
                    const newTipLabel = formatMoney(intl, newTipUnitPrice);
                    setTipAmount(newValue);
                    setTipLabel(newTipLabel);
                  }}
                />
              </div>
            </div>
          )}

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={supportCheckboxValue}
                  onChange={e => setSupportCheckboxValue(e.target.checked)}
                />
              }
              label={<FormattedMessage id="TipModal.supportCheckbox" />}
            />
          </FormGroup>

          <Button type="button" onClick={handleSave}>
            <FormattedMessage id="TipModal.save" />
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenWhy}
        onClose={onCloseWhy}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.modalContent}>
          <FormattedMessage id="TipModal.whyDonateText" />
        </div>
      </Modal>
    </div>
  );
};

export default TipModal;
