import React from 'react';
import css from './QRCodeModal.module.css';
import QRCode from 'react-qr-code';
import { Modal } from '../../..';
import { downloadDivAsImage } from '../../utils';
const QRCodeModal = ({ open, setOpen }) => {
  const currentUrl = typeof window !== 'undefined' && window.location.href;
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        setOpen(false);
      }}
      onManageDisableScrolling={() => {}}
      containerClassName={isMobile && css.modalContent}
    >
      <div
        className={css.qrWrapper}
        id="capture"
        onClick={() => {
          if (!isMobile) {
            downloadDivAsImage('capture');
          }
        }}
      >
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={currentUrl}
          viewBox={`0 0 256 256`}
        />
      </div>
      {!isMobile && (
        <center>
          <p>Click the qr code to download it for sharing</p>
        </center>
      )}
    </Modal>
  );
};

export default QRCodeModal;
