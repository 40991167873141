import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';
import ShareButton from '../../components/ShareButton/ShareButton';

const SectionGallery = props => {
  const { listing, variantPrefix, setOpen } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <div className={css.galleryWrapper}>
      <ListingImageGallery
        rootClassName={css.productGallery}
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
      <ShareButton setOpen={setOpen} />
    </div>
  );
};

export default SectionGallery;
