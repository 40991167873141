import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

export const downloadDivAsImage = async (elementId, filename = 'download.png') => {
  const element = document.getElementById(elementId);
  if (!element) return;
  const canvas = await html2canvas(element);
  canvas.toBlob(blob => {
    saveAs(blob, filename);
  });
};
